.modal {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
    border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 0.5rem 0.5rem;
  background: #002142;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

/* @media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
} */

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}
