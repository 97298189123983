@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0px;
}
* {
  box-sizing: border-box;
}
.pac-container {
  z-index: 999999999999;
}
.css-1ozefeb {
  z-index: 199999999300 !important;
}
.modal {
  z-index: 9999999909 !important;
}
.css-1sucic7 {
  position: fixed;
  z-index: 9999999998 !important;
  inset: 0px;
}
.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  position: fixed;
  z-index: 99999999999999 !important;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}
.css-1ozefeb {
    margin-left: 0 !important;
}
.css-1laqsz7-MuiInputAdornment-root {
  z-index: 999999999 !important;
}
/* .nav-links a:hover, .nav-links a:active, .nav-links a.active
.chackbox_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
} */
.chackbox_container label {
  min-width: 111px;
  text-align: right;
}
.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}
.chackbox_container input {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  background: #ffffff;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

/* .center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* h1 {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #281414;
  z-index: 10;
  margin: 0;
  padding: 0;
} */
/* 
.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

.locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
  cursor: pointer;
}
.App {
  display: flex;
  justify-content: center;
}

label {
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
}

input + label {
  font-weight: bold;
  display: inline-flex;
  margin-right: 20px;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea,
select {
  display: block;
  width: 400px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* margin-bottom: 20px; */
/* } */

/* .form-control {
  margin-bottom: 20px;
}

.error {
  color: red;
}  */
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

/* .nav-links li {
  margin: 1rem;
} */
.css-11b3ww9-MuiPaper-root-MuiAppBar-root{
    border: 1px solid transparent;
      color: #292929;
      text-decoration: none;
      padding: 0.5rem;
}
.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #efefef;
  border-color: none;
  box-shadow: 1px 2px 5px 1px rgb(0 0 0 / 65%);
  color: #292929;
}

.jobslink {
  font-size: 17px;
  background: rgb(25, 118, 210);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 33px;
  width: 43px;
  height: 42px;
  color: rgb(255, 255, 255);
  padding: 7px 5px 0px 0px;
  margin: 0px 18px 0px 0px;
}

.jobslink:hover {
  background: rgb(0, 35, 70);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 33px;
  color: rgb(255, 255, 255);
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 0px;
  font-size: 13px;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 8px;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
}