.str_cat_menu_title {
    font-size: 14px;
}

.str_cat_menu_icon span {
    font-size: 19px;
}
.str_cat_menu_icon img {
    width: 28px;
    margin-bottom: 7px;
}
.get_servise_cont {
    display: inline-block;
    padding: 0px 10px 0px 10px;
}
.get_servise {
    text-align: center;
    background: #ef1e23;
    color: #fff;
    padding: 10px;
    font-size: 20px;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
}
.all_sub_cats a {
    /* padding: 0px 10px 4px 10px; */
    /* background: blue; */
    color: #333;
}
.all_sub_sub_cats a {
    /* padding: 0px 10px 4px 10px; */
    /* background: blue; */
    color: #fff;
}
.the_current_sub_cats a {
    /* padding: 0px 10px 4px 10px; */
    /* background: blue; */
    color: #fff;
}
.all_sub_cats a:hover {
    color: #fff;
}
.all_sub_cats {
    text-align: center;
    display: inline-block;
    padding: 5px 5px 5px 10px;
    font-size: 16px;
    background: #fbfbfb;
    color: #006363;
    margin-top: 10px;
    border: 1px solid #ccc;
}

.all_sub_cats:hover {
    background: #002656;
}
.all_sub_cats_container {
    text-align: center;
    max-width: 1300px;
    margin: 0 auto;
}
.the_current_sub_cats {
    background: #002656;
}
 a.mudgash_cat {
    background: #2967b2;
    color: #fff;
}   
    body {
  font-family: 'Source Sans Pro', sans-serif;
}
* {
  box-sizing: border-box;
}
a {
  color: #333;
}
.menu {
    width: 100%;
}
.menu-container {
  margin: 0 auto;
  background: #e9e9e9;
}
.menu a.logo {
    display: inline-block;
    padding: 1.5em 3em;
    width: 19%;
    float: left;
}
.menu img {
    max-width: 100%;
}
.menu-mobile {
  display: none;
  padding: 20px;
}
.menu-mobile:after {
  content: "\f394";
  font-family: "Ionicons";
  font-size: 2.5rem;
  padding: 0;
  float: right;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.menu-dropdown-icon:before {
  content: "\f489";
  font-family: "Ionicons";
  display: none;
  cursor: pointer;
  float: right;
  padding: 1.5em 2em;
  background: #fff;
  color: #333;
}
.menu > ul {
    margin: 0 auto;
    /* text-align: center; */
    max-width: 1178px;
    list-style: none;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    clear: right;
}
.menu > ul:before,
.menu > ul:after {
  content: "";
  display: table;
}
.menu > ul:after {
  clear: both;
}
.menu > ul > li {
  float: right;
  background: #e9e9e9;
  padding: 0;
  margin: 0;
}
.menu > ul > li a {
    text-decoration: none;
    text-align: center;
    padding: 0.5em 0.7em;
    display: block;
}
.menu > ul > li:hover {
  background: #f0f0f0;
}



.menu > ul > li > ul.normal-sub {
  width: 300px;
  left: auto;
  padding: 10px 20px;
z-index: 999;
}

.y_f_logo_and_nav_container {
    margin-bottom: 0px;
}
.all_sub_sub_cats_container {
    text-align: center;
}

.all_sub_cats.all_sub_sub_cats {
    text-align: center;
    display: inline-block;
    padding: 4px 8px 3px 8px;
    background: #545454;
    margin-top: 23px;
    border-radius: 10px;
    font-size: 15px;
}

.all_sub_cats.all_sub_sub_cats:hover {
    background: #000000;
    transition: 0.5s;
}
.all_sub_cats.all_sub_sub_cats.the_current_sub_sub_cats {
    background: #001733;
}
  .menu > ul > li > ul.normal-sub {
    /*width: 100%;*/
        display: none;
  }
  .menu .show-on-mobile {
    display: none;
  }
</style>
<style>
a {
  color: #333;
}
.menu {
    width: 100%;
}
.menu-container {
  margin: 0 auto;
  background: #e9e9e9;
  box-shadow: 0px 2px 0.3em -2px rgb(101 101 101);
}
.menu a.logo {
    display: inline-block;
    padding: 1.5em 3em;
    width: 19%;
    float: left;
}
.menu img {
    max-width: 100%;
}
.menu-mobile {
  display: none;
  padding: 20px;
}
.menu-mobile:after {
  content: "\f394";
  font-family: "Ionicons";
  font-size: 2.5rem;
  padding: 0;
  float: right;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.menu-dropdown-icon:before {
  content: "\f489";
  font-family: "Ionicons";
  display: none;
  cursor: pointer;
  float: right;
  padding: 1.5em 2em;
  background: #fff;
  color: #333;
}
.menu > ul {
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
    list-style: none;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    clear: right;
}
.menu > ul:before,
.menu > ul:after {
  content: "";
  display: table;
}
.menu > ul:after {
  clear: both;
}
.menu > ul > li {
  float: right;
  background: #e9e9e9;
  padding: 0;
  margin: 0;
}
.menu > ul > li a {
  text-decoration: none;
  /*padding: 1.5em 3em;*/
  display: block;
}
.menu > ul > li:hover {
  background: #f0f0f0;
}
.menu > ul > li > ul {
    display: none;
    width: 100%;
    background: #fff;
    box-shadow: 1px 1px 1em 0 rgb(48 104 178);
    padding: 20px;
    position: absolute;
    z-index: 99;
    left: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    z-index: 999;
}
.menu > ul > li > ul:before,
.menu > ul > li > ul:after {
  content: "";
  display: table;
}
.menu > ul > li > ul:after {
  clear: both;
}
.menu > ul > li > ul > li {
  margin: 0px 10px 0px 0px;
  padding-bottom: 0;
  list-style: none;
  width: 150px;
  background: none;
  float: right;
}
.menu > ul > li > ul > li a {
    /*color: #777; */
    padding: 0;
    width: 149px;
    display: block;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    text-align: right;
}
.menu > ul > li > ul > li a:hover{
	color:#03a9f4;
}
.menu > ul > li > ul > li > ul {
    display: block;
    padding: 0;
    margin: 3px 0 0;
    list-style: none;
    box-sizing: border-box;
    max-height: 109px;
    overflow: auto;
    overflow-x: hidden;
    margin-bottom: 20px;
}
.menu > ul > li > ul > li > ul:before,
.menu > ul > li > ul > li > ul:after {
  content: "";
  display: table;
}
.menu > ul > li > ul > li > ul:after {
  clear: both;
}
.menu > ul > li > ul > li > ul > li {
    float: left;
    width: 100%;
    padding: 0px 0;
    margin: 0;
    font-size: .8em;
}
.menu > ul > li > ul > li > ul > li a {
  border: 0;    
  font-size: 13px;
  color: #848484;
    text-align: right;
}
.menu > ul > li > ul.normal-sub {
  width: 300px;
  left: auto;
  padding: 10px 20px;
}
.menu > ul > li > ul.normal-sub > li {
  width: 100%;
}
.menu > ul > li > ul.normal-sub > li a {
  border: 0;
  padding: 1em 0;
}
.second_cat_in_nav {
    font-weight: 500;
}
.menu > ul ::-webkit-scrollbar{
width:5px
}
.menu > ul ::-webkit-scrollbar-track{
background:#ffffff;
border-radius:2px
}
.menu > ul ::-webkit-scrollbar-thumb{
background:#2967b2;
border-radius:2px
}
.menu > ul  ::-webkit-scrollbar{
width:5px
}
.menu > ul  ::-webkit-scrollbar-track{
background:#d8d8d8;
border-radius:10px
}
.menu > ul  ::-webkit-scrollbar-thumb{
/*background:#0e5a75;*/
background:#fe0000;
border-radius:21px
}
@media only screen and (max-width: 900px) {
.menu > ul > li a {
    padding: 2px 3px;
}
}
@media only screen and (max-width: 100px) {
  .menu-container {
    width: 100%;
  }
  .menu-container .menu{
	display:inline-block;
   }
  .menu-mobile {
    display: block;    
    float: right;    
    padding: 20px 20px 0;
  }
  .menu-dropdown-icon:before {
    display: block;
  }
  .menu > ul {
    display: none;
    width:100%;
  }
  .menu > ul > li {
    width: 100%;
    float: none;
    display: block;
  }
  .menu > ul > li a {
    padding: 1.5em;
    width: 100%;
    display: block;
  }
  .menu > ul > li > ul {
    position: relative;    
    padding: 0 40px;
  }
  .menu > ul > li > ul.normal-sub {
    width: 100%;
  }
  .menu > ul > li > ul > li {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  .menu > ul > li > ul > li:first-child {
    margin: 0;
  }
  .menu > ul > li > ul > li > ul {
    position: relative;
  }
  .menu > ul > li > ul > li > ul > li {
    float: none;
  }
  .menu .show-on-mobile {
    display: block;
  }
}
@media only screen and (max-width: 900px) {
.menu > ul > li a {
    padding: 7px 5px;
}
.str_cat_menu_title {
    font-size: 12px;
}
.str_cat_menu_icon img {
    width: 24px;
}
}
@media only screen and (max-width: 550px) {
.str_cat_menu_title {
    max-width: 71px;
    min-height: 35px;
}
.menu > ul > li {
    float: right;
    /* background: #b3b3b3; */
    /* border-left: 1px solid; */
    padding: 2px;
    margin: 0;
    width: 67px;
}
.all_sub_cats {
    text-align: center;
    /* display: inline-block; */
    float: right;
    padding: 1px 3px 2px 3px;
    font-size: 14px;
    background: #fbfbfb;
    color: #006363;
    margin-top: 5px;
    margin-left: 1px;
    margin-right: 4px;
    border: 1px solid #ccc;
}
.menu > ul > li a {
    padding: 1px 4px;
}
}