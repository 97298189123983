.loading_locations {
    animation: pulsevlr 2s infinite;
}

@keyframes pulsevlr {
  0% {
        background: #fff;
  }
  
  40% {
     background: #fff;
    }
  50% {
     background: #fffffff4;
  }
  80% {
    background: #ffffffde;
  }

  100% {
     background: #fff;
  }
}