.single_contact_container:nth-child(even) {
    background: rgb(183, 183, 183);
}

.single_contact_container {
    animation: pulsevl 50s infinite;
}
@keyframes pulsevl {
  0% {
    background-position: center top;
        background-size: 150%;
  }
  10% {
    background-position: center;
        background-size: 120%;
  }
  20% {
    background-position: bottom;
        background-size: 140%;
  }
  30% {
    background-position: center;
        background-size: 160%;
  }
  40% {
    background-position: center top;
        background-size: 120%;
  }
  70% {
    background-position: bottom;
        background-size: 150%;
  }
  80% {
    background-position: center top;
        background-size: 170%;
  }

  100% {
    background-position: center top;
        background-size: 150%;
  }
}