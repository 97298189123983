.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #1976d2;
  border-radius: 4px;
  background: #1976d2;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #003a72;
  border-color: #ffffff;
}

.button--inverse {
  background: transparent;
  color: #001f3e;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #001f3e;
}

.button--danger {
  background: #001021;
  border-color: #001021;
}

.button--danger:hover,
.button--danger:active {
  background: #001f3e;
  border-color: #001f3e;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}