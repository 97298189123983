.item {
    margin: 1rem 0;
    background-color: #575757;
    padding: 1rem;
  }
  
  .item h3 {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }
  
  .item header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .quantity span {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .price {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .itemprice {
    font-weight: normal;
    font-size: 1rem;
    font-style: italic;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
  }
  
  .actions button {
    border: 0px solid #5e5e5e;
    border-radius: 10px;
    background: #1976d2;
    margin-left: 0.5rem;
    padding: 3px 12px;
    transition: 0.5s;
    cursor: pointer;
    color: white;
    margin-bottom: 0px;
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #001f3e;
    color: white;
  }