@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
.form_tag_container {
    z-index: 99;
max-width: 300px;
background: #fff;
display: flex;
position: absolute;
flex-direction: column;
}

.tabs_date {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}
.tabs_date_item{
    margin-left: 5px;
    max-width: 218px;
    background: #525252;
    color: #fff;
    padding: 7px;
    cursor: pointer;
    transition: 0.5s;
}
.tabs_date_item:hover {
    background: #1976d2;
}

.tabs_date_item:active {
    background: #002346;
}
.soonWord {
    margin-left: 5px;
    max-width: 218px;
    background: #dddddd;
    color: #383838;
    padding: 19px;
    cursor: pointer;
    text-align: center;
    margin-top: 16px;
}
.react-datepicker__input-container input#date_delivery {
    border-radius: 10px;
    padding: 8px;
}

.button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #1976d2;
  border-radius: 4px;
  background: #1976d2;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #003a72;
  border-color: #ffffff;
}

.button--inverse {
  background: transparent;
  color: #001f3e;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #001f3e;
}

.button--danger {
  background: #001021;
  border-color: #001021;
}

.button--danger:hover,
.button--danger:active {
  background: #001f3e;
  border-color: #001f3e;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-upload__preview {
  width: 13rem;
  height: 13rem;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

.image-upload__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.modal {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
    border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 0.5rem 0.5rem;
  background: #002142;
  color: white;
}

.modal__header h2 {
  margin: 0.5rem;
}

.modal__content {
  padding: 1rem 0.5rem;
}

.modal__footer {
  padding: 1rem 0.5rem;
}

/* @media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
} */

.modal-enter {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  -webkit-transform: translateY(-10rem);
          transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

/* .nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #efefef;
  border-color: none;
  box-shadow: 1px 2px 5px 1px rgb(0 0 0 / 65%);
  color: #292929;
}

.jobslink {
    font-size: 17px;
    background: rgb(25, 118, 210);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 33px;
    width: 43px;
    height: 42px;
    color: rgb(255, 255, 255);
    padding: 7px 5px 0px 0px;
    margin: 0px 18px 0px 0px;
}
.jobslink:hover {
    background: rgb(0, 35, 70);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 33px;
    color: rgb(255, 255, 255);
}


.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }
  
  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
} */

.searchInputs {
    display: flex;
    border: 1px solid;
    border-radius: 12px;
    justify-content: space-around;
    align-items: center;
  }
  
  .search input {
    background-color: white;
    border: 0;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 18px;
    padding: 15px 15px 13px 3px;
    height: 59px;
    width: 158px;
  }
  
  .searchIcon {
    /* height: 60px; */
    /* width: 50px; */
    background-color: white;
    /* display: flex; */
    /* place-items: end; */
    border-radius: 10px;
    /* position: absolute; */
    /* left: 0; */
  }
  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  
  .dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
  }
  
  /* .dataResult::-webkit-scrollbar {
    display: none;
  } */
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  /* a {
    text-decoration: none;
  } */

.singleResult {
    margin: 6px;
    border-bottom: 1px solid #d3d3d3;
    padding: 4px;
    cursor: pointer;
    transition: 0.5s;
}

.singleResult:hover {
    background-color: #1976d2;
    color: #f9f9f9;
}
  
  #clearBtn {
    cursor: pointer;
  }
.form_tag_container {
    z-index: 99;
max-width: 300px;
background: #fff;
display: flex;
position: absolute;
flex-direction: column;
}

.tabs_date {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}
.tabs_date_item{
    margin-left: 5px;
    max-width: 218px;
    background: #525252;
    color: #fff;
    padding: 7px;
    cursor: pointer;
    transition: 0.5s;
}
.tabs_date_item:hover {
    background: #1976d2;
}

.tabs_date_item:active {
    background: #002346;
}
.soonWord {
    margin-left: 5px;
    max-width: 218px;
    background: #dddddd;
    color: #383838;
    padding: 19px;
    cursor: pointer;
    text-align: center;
    margin-top: 16px;
}
.react-datepicker__input-container input#date_delivery {
    border-radius: 10px;
    padding: 8px;
}
.search input {
    background-color: white;
    border: 0;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 15px;
    padding: 9px 15px 13px 3px;
    height: 0px;
    width: 158px;
}
.searchInputs {
    display: flex;
    border: 1px solid;
    border-radius: 0px;
    justify-content: space-around;
    align-items: center;
}
.searchIcon {
    /* height: 60px; */
    /* width: 50px; */
    background-color: white;
    /* display: flex; */
    /* place-items: end; */
    border-radius: 10px;
    /* position: absolute; */
    /* left: 0; */
}
.searchIcon svg {
    font-size: 26px;
}

.leaflet-container {
    width: 100%;
    height: calc(90vh - 90px);
  }
/* .Block{
    display: flex;
    align-items: center;
    flex-direction: column;
} */
/* #Resizable{
    border: 1px solid black; 
    height: 200px;
    width: 200px;
} */
#Draggable{
    background: rgba(1, 1, 1, 0.2);
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    cursor: col-resize;
    height: 20px;
    width: 10px;
}
.cell_t {
    width: 100px;
    float: right;
    height: 100%;
    padding-top: 7px;
    font-size: 14px;
}

.headet_t {
    width: 5000px;
    height: 160px;
    text-align: center;
}

.container_t {
    /* border: 1px solid red; */
    margin-top: 30px;
}
.card {
  position: relative;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  padding: 1rem;
  overflow: hidden;
  background: white;
}

.user-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.user-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.user-item a:hover,
.user-item a:active {
  background: #ffd900;
}

.user-item__content {
  padding: 0;
}

.user-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.user-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #ffd900;
}

.user-item:hover h2,
.user-item:active h2,
.user-item:hover h3,
.user-item:active h3 {
  color: #292929;
}

.user-item__info h3 {
  margin: 0;
}
.users-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 90%;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #1976d2;
  border-color: #1976d2 transparent #1976d2 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.single_contact_container:nth-child(even) {
    background: rgb(183, 183, 183);
}
.loading_locations {
    -webkit-animation: pulsevlr 2s infinite;
            animation: pulsevlr 2s infinite;
}

@-webkit-keyframes pulsevlr {
  0% {
        background: #fff;
  }
  
  40% {
     background: #fff;
    }
  50% {
     background: #fffffff4;
  }
  80% {
    background: #ffffffde;
  }

  100% {
     background: #fff;
  }
}

@keyframes pulsevlr {
  0% {
        background: #fff;
  }
  
  40% {
     background: #fff;
    }
  50% {
     background: #fffffff4;
  }
  80% {
    background: #ffffffde;
  }

  100% {
     background: #fff;
  }
}
.searchInputs {
    display: flex;
    border: 1px solid;
    border-radius: 12px;
    justify-content: space-around;
    align-items: center;
  }
  
  .search input {
    background-color: white;
    border: 0;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 18px;
    padding: 15px 15px 13px 3px;
    height: 59px;
    width: 158px;
  }
  
  .searchIcon {
    /* height: 60px; */
    /* width: 50px; */
    background-color: white;
    /* display: flex; */
    /* place-items: end; */
    border-radius: 10px;
    /* position: absolute; */
    /* left: 0; */
  }
  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  
  .dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
  }
  
  /* .dataResult::-webkit-scrollbar {
    display: none;
  } */
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  /* a {
    text-decoration: none;
  } */

.singleResult {
    margin: 6px;
    border-bottom: 1px solid #d3d3d3;
    padding: 4px;
    cursor: pointer;
    transition: 0.5s;
}

.singleResult:hover {
    background-color: #1976d2;
    color: #f9f9f9;
}
  
  #clearBtn {
    cursor: pointer;
  }
.Cart_cart__1dwij {
    max-width: 30rem;
    background-color: #313131;
    color: white;
    position: absolute;
    z-index: 89;
    overflow: scroll;
    max-height: 600px;
  }
  
  .Cart_cart__1dwij h2 {
    font-size: 1.25rem;
    margin: 0.5rem 0;
  }
  
  .Cart_cart__1dwij ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
.CartItem_item__3zNwm {
    margin: 1rem 0;
    background-color: #575757;
    padding: 1rem;
  }
  
  .CartItem_item__3zNwm h3 {
    font-size: 13px;
    font-weight: 400;
    margin: 0;
  }
  
  .CartItem_item__3zNwm header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .CartItem_details__1hEvp {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .CartItem_quantity__2F4HY span {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .CartItem_price__20bRZ {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .CartItem_itemprice__2VSmQ {
    font-weight: normal;
    font-size: 1rem;
    font-style: italic;
  }
  
  .CartItem_actions__xwCiW {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
  }
  
  .CartItem_actions__xwCiW button {
    border: 0px solid #5e5e5e;
    border-radius: 10px;
    background: #1976d2;
    margin-left: 0.5rem;
    padding: 3px 12px;
    transition: 0.5s;
    cursor: pointer;
    color: white;
    margin-bottom: 0px;
  }
  
  .CartItem_actions__xwCiW button:hover,
  .CartItem_actions__xwCiW button:active {
    background-color: #001f3e;
    color: white;
  }
.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
.radio-form-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.radio-form-container label {
  font-weight: 600;
}
.radio-form-container .form-control {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 6px;
}
.radio-form-container .form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  min-width: 30px;
}
.radio-form-container .chackbox_container {
  display: flex;
  justify-content: flex-start;
  width: unset;
  min-width: 83px;
}
.radio-form-container .chackbox_container label {
  font-weight: 400;
  margin-bottom: 0.5rem;
  min-width: 22px;
}
.place-form {
  /* position: relative; */
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
    position: fixed;
    z-index: 99;
    max-width: 289px;
}
.place-form2 {
  /* position: relative; */
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  /* width: 90%; */
  max-width: 40rem;
  box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
  border-radius: 6px;
  background: white;
  position: absolute;
  max-height: 100vh;
  z-index: 99;
  max-width: 289px;
  /* display: flex; */
  overflow: auto;
  flex-direction: column;
}

.slideshowContainer {
  position: relative;
  overflow: hidden;
  /*margin: 0px 0 0px;*/
  margin: 0 auto;
  width: 100%;
  height: 561px;
}

.imageSlides {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 100%;
  /*min-height: 100%;*/
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: -1;
}

/* add 'visible' class via Javascript */
.visible {
  opacity: 1;
}

.slideshowArrow {
  font-size: 7em;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.slideshowArrow:hover {
  opacity: 0.75;
}

#leftArrow {
  position: absolute;
  left: 4%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#rightArrow {
  position: absolute;
  right: 4%;
  top: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.slideshowCircles {
  position: absolute;
  bottom: 2%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.circle {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid 2px rgba(255, 255, 255, 0.5);
  transition: 1s ease-in-out;
}

.dot {
  background-color: rgba(255, 255, 255, 0.7);
  border: solid 2px rgba(255, 255, 255, 0.5);
}
.map {
  width: 100%;
  height: 100%;
}

img{max-width:100%; max-height: 100%;}
	*{transition: all .5s ease;-moz-transition: all .5s ease;-webkit-transition: all .5s ease}
.my-list {
    background: #fbfbfb;
    width: 100%;
    padding: 10px;
    border: 1px solid #d8d8d86b;
    float: left;
    margin: 15px 0;
    /* border-radius: 5px; */
    box-shadow: 2px 3px 6px #eaeaea99;
    position: relative;
    overflow: hidden;
}
.my_list_img {
    width: 100%;
    height: 250px;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.my_list_img img:hover {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    /* max-width: 100%; */
    /* height: 100%; */
}
.my-list h3:hover {
    color: #2f68b2;
    cursor: pointer;
}
a.btn.btn-info {
    position: absolute;
    top: 35%;
    left: 35%;
}
.my_beac_color {
    background: #ffffffd9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}
.my-list a.btn.btn-info {
    top: 35%;
    left: 35%;
    width: 109px;
    height: 104px;
    padding: 39px 16px;
    color: #fff;
    border: 2px solid #fff;
    background: #000000c7;
    border-radius: 100px;
    /* font-size: 24px; */
}
.my-list a.btn.btn-info:hover {
    background: #000000;
}
.my-list h3{
    text-align: right;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #ccc4c4;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-bottom: 5px;
    min-height: 72px;
    }
.my-list span{
    float:left;
    font-weight: bold;
}
.my-list span:last-child{
    float:right;
}
.my-list .offer{
    width: 100%;
    float: left;
    margin: 5px 0;
    border-top: 1px solid #ccc4c4;
    margin-top: 5px;
    padding-top: 5px;
    color: #00b506;
    }
.detail {
    position: absolute;
    top: -107%;
    left: 0;
    text-align: center;
    background: #fff;
    height: 100%;
    width:100%;
    display: none;
	
}
	
.my-list:hover .detail{top:0;}
.product_top_details_kupon {
    text-align: center;
}
.go_to_advertise {
    float: right;
    width: 260px;
    height: 54px;
    position: relative;
}
.go_to_advertise img {
    width: 50px;
    position: absolute;
    z-index: 99;
}
.go_to_advertise_title {
    top: 12px;
    width: 304px;
    text-align: center;
    background: #ef1e23;
    right: 5px;
    height: 31px;
    position: absolute;
    padding-top: 7px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
}
.go_to_inside_kupon_cont {
    float: right;
    width: 100%;
    margin-top: 14px;
}
.go_to_inside_kupon {
    float: left;
    /* top: 12px; */
    width: 37px;
    /* margin: 0 auto; */
    text-align: center;
    /* background: #ef1e23; */
    color: #fff;
    /* right: 5px; */
    height: 24px;
    /* position: absolute; */
    /* padding-top: 3px; */
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
}
.selected_go_to_inside_kupon {
    /* top: 12px; */
    width: 260px;
    margin: 0 auto;
    text-align: center;
    background: #ef1e23;
    color: #fff;
    /* right: 5px; */
    height: 51px;
    /* position: absolute; */
    padding-top: 10px;
    font-size: 21px;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;
}
    .kupon_container {
    max-width:  100%;
}
.amount_details {
    margin-top: 10px;
}
.amount_pre_price {
    color: #8d8d8d;
    text-decoration: line-through;
}
.amount_price {
    color: #2f2f2f;
    font-size: 30px;
    font-weight: 200;
}
.title_kupon {
    text-align: center;
    color: #3b3b3b;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
    min-height: 75px;
}
.kupon_dib {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.the_kupon {
    border: 1px dashed #aeaeae;
    border-radius: 10px;
    padding: 5px;
    height: 412px;
    /* float: right; */
    /* position: relative; */
    overflow: hidden;
    background: #efefef9c;
    margin-bottom: 30px;
}

.number_kupon {
    /* float:  right; */
    width: 58px;
    font-size:  36px;
    text-align: center;
    border: 4px solid #8f8f8f;
    box-shadow: 3px -1px #000000;
    -moz-border-radius: 75px;
    -webkit-border-radius: 75px;
    margin-right: 5px;
    margin-top: 5px;
    z-index: 999;
    position:  absolute;
}

.logo_kupon {
    width: 100%;
    height: 184px;
    background: #fff;
    text-align: center;
}

.logo_kupon img {
    max-width: 100%;
    max-height: 100%;
}
.amount_details {
/*    position:  absolute;
    bottom:  0;
    width:  100%;
    text-align:  right;
    padding-right: 10px;
    padding-top: 3px;
    z-index: 99;
    background: #6c6c6c4d;*/
}

.amount_kupon {
    font-size: 19px;
    font-weight: 300;
    /*margin-top: 10px;*/
    /* color: #040303; */
    /* text-shadow: 2px -2px 3px rgba(240, 255, 0, 0.92); */
    /* float: right; */
}

.amount_desc_kupon {
    /* float: right; */
    padding-left: 9px;
    /* padding-top: 7px; */
    color: #000;
    /* text-shadow: 2px -2px 3px rgba(255, 255, 255, 0.83);*/
} 
.form_box {
    width: 32%;
    float: right;
    margin-left: 10px;
}
.product_hot_container {
    width: 100%;
}
.more_details_p {
    font-size:  20px;
    font-weight:  300;
    margin-top: 20px;
    line-height: 37px;
    max-width: 550px;
}

span.kupon_in_details {
    font-weight:  bold;
    margin-left: 20px;
}
.all_technical_container {
    text-align: right;
    background: #e5e5e5;
    padding: 8px;
}
@media screen and (max-width: 450px){
.my_list_img {
    width: 100%;
    height: 50px;
    text-align: center;
}
#id_reg_prod_box .col-xs-6 {
    padding: unset;
}
.my_list_img {
    height: 75px;
}
.my-list h3 {
    text-align: right;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #ccc4c4;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-bottom: 5px;
    min-height: 72px;
}
.amount_details {
    margin-top: 3px;
    line-height: 17px;
    font-size: 12px;
    width: 100%;
}
.amount_pre_price {
    text-align: center;
}
.amount_price {
    text-align: center;
}
.my-list .offer {
    font-weight: 300;
    font-size: 12px;
}
.go_to_inside_kupon_cont {
    margin-top: 0px;
}
.go_to_inside_kupon {
    /* top: 12px; */
    width: 100%;
    margin: 0 auto;
    text-align: center;
    /*background: #ef1e23;*/
    /*color: #fff;*/
    /* right: 5px; */
    height: 30px;
    /* position: absolute; */
    padding-top: 3px;
    font-size: 15px;
    font-weight: 400;
    /*border-radius: 10px;*/
    margin-top: 0px;
    /*cursor: pointer;*/
}
.col-xs-12 {
    width: 100%;
    padding: unset;
    padding-right: 6px;
}
.amount_desc_kupon {
    min-height: 18px;
}


}
.pirurim {
    color: #3068b2;
    font-weight: 400;
}
.search_widgets_y {
    margin: 40px 0px 40px 0px;
    text-align: center;
}

input.search_input_btn_y {
    border: 1px solid #cacaca;
    font-size: 16px;
    padding: 6px;
    color: #5a5a5a;
    margin-left: 10px;
}

button.search_submit_y {
    background: #afafaf;
    /* height: 18px; */
    width: 85px;
    font-size: 16px;
    border: 1px solid #cacaca;
    font-size: 16px;
    padding: 6px;
    color: #ffffff;
    margin-left: 10px;
}

button.search_submit_y:hover {
    background: #122448;
}
.add_to_card_p {
    width: 100%;
    overflow: hidden;
}

.add_to_card_p #number_of_same_product {
    width: 62px;
    float: right;
    height: 27px;
    border-radius: 0px;
    border: 1px solid #bbb;
    font-size: 16px;
    padding-right: 5px;
    margin-left: 8px;
}

.add_to_card_button_p.add_cart_selected.go_shop {
    color: #ffffff;
    background: #9a0004;
    border-radius: 0px;
    padding: 4px 7px 5px 9px;
    font-weight: 300;
    margin-top: 0px;
    font-size: 13px;
    display: block;
    float: right;
}
</style>
<style>
    .cart_icon img {
        width: 70px;
    }
    .cart_screen{
    position: fixed;
    width: 250px;
    height: 700px;
    left: 80px;
    top: 0px;
    background-color: rgb(154 0 4);
    z-index: 9998;
    }
    .cart_icon {
    position: fixed;
    top: 150px;
    left: 150px;
    z-index: 9999;
    /* border: 1px solid red; */
     display: none;
    }
    
.price_txt_anim {
    position: fixed;
    top: 0px;
    left: 180px;
    z-index: 9999;
    /* border: 1px solid red; */
    font-size: 15px;
    font-weight: bold;
    /* text-shadow: 2px 2px 1px #000000; */
    color: #3165ab;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding-top: 12px;
}
@-webkit-keyframes fadeAndScale2 {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
                transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
        /* transform: translate(200px , -200px); */

    }

    30% {
        opacity: 0;
        -webkit-transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
                transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
        /* transform: translate(100px , -100px), rotate(90deg); */
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1,1,1) skew(0deg, 0deg);
                transform: scale3d(1,1,1) skew(0deg, 0deg);
        /* transform: translate(0px , 0px), rotate(160deg); */
    }
}
@keyframes fadeAndScale2 {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
                transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
        /* transform: translate(200px , -200px); */

    }

    30% {
        opacity: 0;
        -webkit-transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
                transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
        /* transform: translate(100px , -100px), rotate(90deg); */
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1,1,1) skew(0deg, 0deg);
                transform: scale3d(1,1,1) skew(0deg, 0deg);
        /* transform: translate(0px , 0px), rotate(160deg); */
    }
}
.fade_in_y1 {
    -webkit-transform-origin: top center;
            transform-origin: top center;
    transition: 0.8s;
}
.place-list {
  list-style: none;
  margin: 1rem auto;
  padding: 0;
  width: 90%;
  max-width: 40rem;
}

.str_cat_menu_title {
    font-size: 14px;
}

.str_cat_menu_icon span {
    font-size: 19px;
}
.str_cat_menu_icon img {
    width: 28px;
    margin-bottom: 7px;
}
.get_servise_cont {
    display: inline-block;
    padding: 0px 10px 0px 10px;
}
.get_servise {
    text-align: center;
    background: #ef1e23;
    color: #fff;
    padding: 10px;
    font-size: 20px;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
}
.all_sub_cats a {
    /* padding: 0px 10px 4px 10px; */
    /* background: blue; */
    color: #333;
}
.all_sub_sub_cats a {
    /* padding: 0px 10px 4px 10px; */
    /* background: blue; */
    color: #fff;
}
.the_current_sub_cats a {
    /* padding: 0px 10px 4px 10px; */
    /* background: blue; */
    color: #fff;
}
.all_sub_cats a:hover {
    color: #fff;
}
.all_sub_cats {
    text-align: center;
    display: inline-block;
    padding: 5px 5px 5px 10px;
    font-size: 16px;
    background: #fbfbfb;
    color: #006363;
    margin-top: 10px;
    border: 1px solid #ccc;
}

.all_sub_cats:hover {
    background: #002656;
}
.all_sub_cats_container {
    text-align: center;
    max-width: 1300px;
    margin: 0 auto;
}
.the_current_sub_cats {
    background: #002656;
}
 a.mudgash_cat {
    background: #2967b2;
    color: #fff;
}   
    body {
  font-family: 'Source Sans Pro', sans-serif;
}
* {
  box-sizing: border-box;
}
a {
  color: #333;
}
.menu {
    width: 100%;
}
.menu-container {
  margin: 0 auto;
  background: #e9e9e9;
}
.menu a.logo {
    display: inline-block;
    padding: 1.5em 3em;
    width: 19%;
    float: left;
}
.menu img {
    max-width: 100%;
}
.menu-mobile {
  display: none;
  padding: 20px;
}
.menu-mobile:after {
  content: "\f394";
  font-family: "Ionicons";
  font-size: 2.5rem;
  padding: 0;
  float: right;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.menu-dropdown-icon:before {
  content: "\f489";
  font-family: "Ionicons";
  display: none;
  cursor: pointer;
  float: right;
  padding: 1.5em 2em;
  background: #fff;
  color: #333;
}
.menu > ul {
    margin: 0 auto;
    /* text-align: center; */
    max-width: 1178px;
    list-style: none;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    clear: right;
}
.menu > ul:before,
.menu > ul:after {
  content: "";
  display: table;
}
.menu > ul:after {
  clear: both;
}
.menu > ul > li {
  float: right;
  background: #e9e9e9;
  padding: 0;
  margin: 0;
}
.menu > ul > li a {
    text-decoration: none;
    text-align: center;
    padding: 0.5em 0.7em;
    display: block;
}
.menu > ul > li:hover {
  background: #f0f0f0;
}



.menu > ul > li > ul.normal-sub {
  width: 300px;
  left: auto;
  padding: 10px 20px;
z-index: 999;
}

.y_f_logo_and_nav_container {
    margin-bottom: 0px;
}
.all_sub_sub_cats_container {
    text-align: center;
}

.all_sub_cats.all_sub_sub_cats {
    text-align: center;
    display: inline-block;
    padding: 4px 8px 3px 8px;
    background: #545454;
    margin-top: 23px;
    border-radius: 10px;
    font-size: 15px;
}

.all_sub_cats.all_sub_sub_cats:hover {
    background: #000000;
    transition: 0.5s;
}
.all_sub_cats.all_sub_sub_cats.the_current_sub_sub_cats {
    background: #001733;
}
  .menu > ul > li > ul.normal-sub {
    /*width: 100%;*/
        display: none;
  }
  .menu .show-on-mobile {
    display: none;
  }
</style>
<style>
a {
  color: #333;
}
.menu {
    width: 100%;
}
.menu-container {
  margin: 0 auto;
  background: #e9e9e9;
  box-shadow: 0px 2px 0.3em -2px rgb(101 101 101);
}
.menu a.logo {
    display: inline-block;
    padding: 1.5em 3em;
    width: 19%;
    float: left;
}
.menu img {
    max-width: 100%;
}
.menu-mobile {
  display: none;
  padding: 20px;
}
.menu-mobile:after {
  content: "\f394";
  font-family: "Ionicons";
  font-size: 2.5rem;
  padding: 0;
  float: right;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
}
.menu-dropdown-icon:before {
  content: "\f489";
  font-family: "Ionicons";
  display: none;
  cursor: pointer;
  float: right;
  padding: 1.5em 2em;
  background: #fff;
  color: #333;
}
.menu > ul {
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
    list-style: none;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    clear: right;
}
.menu > ul:before,
.menu > ul:after {
  content: "";
  display: table;
}
.menu > ul:after {
  clear: both;
}
.menu > ul > li {
  float: right;
  background: #e9e9e9;
  padding: 0;
  margin: 0;
}
.menu > ul > li a {
  text-decoration: none;
  /*padding: 1.5em 3em;*/
  display: block;
}
.menu > ul > li:hover {
  background: #f0f0f0;
}
.menu > ul > li > ul {
    display: none;
    width: 100%;
    background: #fff;
    box-shadow: 1px 1px 1em 0 rgb(48 104 178);
    padding: 20px;
    position: absolute;
    z-index: 99;
    left: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    z-index: 999;
}
.menu > ul > li > ul:before,
.menu > ul > li > ul:after {
  content: "";
  display: table;
}
.menu > ul > li > ul:after {
  clear: both;
}
.menu > ul > li > ul > li {
  margin: 0px 10px 0px 0px;
  padding-bottom: 0;
  list-style: none;
  width: 150px;
  background: none;
  float: right;
}
.menu > ul > li > ul > li a {
    /*color: #777; */
    padding: 0;
    width: 149px;
    display: block;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    text-align: right;
}
.menu > ul > li > ul > li a:hover{
	color:#03a9f4;
}
.menu > ul > li > ul > li > ul {
    display: block;
    padding: 0;
    margin: 3px 0 0;
    list-style: none;
    box-sizing: border-box;
    max-height: 109px;
    overflow: auto;
    overflow-x: hidden;
    margin-bottom: 20px;
}
.menu > ul > li > ul > li > ul:before,
.menu > ul > li > ul > li > ul:after {
  content: "";
  display: table;
}
.menu > ul > li > ul > li > ul:after {
  clear: both;
}
.menu > ul > li > ul > li > ul > li {
    float: left;
    width: 100%;
    padding: 0px 0;
    margin: 0;
    font-size: .8em;
}
.menu > ul > li > ul > li > ul > li a {
  border: 0;    
  font-size: 13px;
  color: #848484;
    text-align: right;
}
.menu > ul > li > ul.normal-sub {
  width: 300px;
  left: auto;
  padding: 10px 20px;
}
.menu > ul > li > ul.normal-sub > li {
  width: 100%;
}
.menu > ul > li > ul.normal-sub > li a {
  border: 0;
  padding: 1em 0;
}
.second_cat_in_nav {
    font-weight: 500;
}
.menu > ul ::-webkit-scrollbar{
width:5px
}
.menu > ul ::-webkit-scrollbar-track{
background:#ffffff;
border-radius:2px
}
.menu > ul ::-webkit-scrollbar-thumb{
background:#2967b2;
border-radius:2px
}
.menu > ul  ::-webkit-scrollbar{
width:5px
}
.menu > ul  ::-webkit-scrollbar-track{
background:#d8d8d8;
border-radius:10px
}
.menu > ul  ::-webkit-scrollbar-thumb{
/*background:#0e5a75;*/
background:#fe0000;
border-radius:21px
}
@media only screen and (max-width: 900px) {
.menu > ul > li a {
    padding: 2px 3px;
}
}
@media only screen and (max-width: 100px) {
  .menu-container {
    width: 100%;
  }
  .menu-container .menu{
	display:inline-block;
   }
  .menu-mobile {
    display: block;    
    float: right;    
    padding: 20px 20px 0;
  }
  .menu-dropdown-icon:before {
    display: block;
  }
  .menu > ul {
    display: none;
    width:100%;
  }
  .menu > ul > li {
    width: 100%;
    float: none;
    display: block;
  }
  .menu > ul > li a {
    padding: 1.5em;
    width: 100%;
    display: block;
  }
  .menu > ul > li > ul {
    position: relative;    
    padding: 0 40px;
  }
  .menu > ul > li > ul.normal-sub {
    width: 100%;
  }
  .menu > ul > li > ul > li {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
  .menu > ul > li > ul > li:first-child {
    margin: 0;
  }
  .menu > ul > li > ul > li > ul {
    position: relative;
  }
  .menu > ul > li > ul > li > ul > li {
    float: none;
  }
  .menu .show-on-mobile {
    display: block;
  }
}
@media only screen and (max-width: 900px) {
.menu > ul > li a {
    padding: 7px 5px;
}
.str_cat_menu_title {
    font-size: 12px;
}
.str_cat_menu_icon img {
    width: 24px;
}
}
@media only screen and (max-width: 550px) {
.str_cat_menu_title {
    max-width: 71px;
    min-height: 35px;
}
.menu > ul > li {
    float: right;
    /* background: #b3b3b3; */
    /* border-left: 1px solid; */
    padding: 2px;
    margin: 0;
    width: 67px;
}
.all_sub_cats {
    text-align: center;
    /* display: inline-block; */
    float: right;
    padding: 1px 3px 2px 3px;
    font-size: 14px;
    background: #fbfbfb;
    color: #006363;
    margin-top: 5px;
    margin-left: 1px;
    margin-right: 4px;
    border: 1px solid #ccc;
}
.menu > ul > li a {
    padding: 1px 4px;
}
}
.place-item {
  margin: 1rem 0;
}

.place-item__content {
  padding: 0;
}

.place-item__info {
  padding: 1rem;
  text-align: center;
}

.place-item__image {
  width: 100%;
  height: 12.5rem;
  margin-right: 1.5rem;
}

.place-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place-item__info h2,
.place-item__info h3,
.place-item__info p {
  margin: 0 0 0.5rem 0;
}

.place-item__actions {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

.place-item__modal-content {
  padding: 0;
}

.place-item__modal-actions {
  text-align: right;
}

.place-item__actions button,
.place-item__actions a {
  margin: 0.5rem;
}

.map-container {
  height: 15rem;
  width: 100%;
}

@media (min-width: 768px) {
  .place-item__image {
    height: 20rem;
  } 
}
.place-list {
  list-style: none;
  margin: 1rem auto;
  padding: 0;
  width: 90%;
  max-width: 40rem;
}

.messenger {
  max-width: 1000px;
  height: calc(100vh - 151px);
  display: flex;
  margin: 0 auto;
}

.chatMenu {
  flex: 3.5 1;
  overflow-y: scroll;
}

.chatMenuInput {
  /* width: 90%; */
  min-height: 60px;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid gray;
}

.chatBox {
  flex: 5.5 1;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chatBoxTop {
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
}

.chatBoxBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatMessageInput {
  width: 100%;
  height: 90px;
  padding: 17px;
  border-radius: 25px;
  border: 1px solid #1976d230;
}

.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: teal;
  color: white;
}

.chatOnline {
  flex: 3 1;
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
  padding: 10px;
  height: 100%;
}

.noConversationText {
  position: absolute;
  top: 10%;
  font-size: 50px;
  color: rgb(224, 220, 220);
  cursor: default;
}

@media screen and (max-width: 768px) {
  .chatMenu {
    flex: 1 1;
  }

  .chatMenuInput {
    display: none;
  }

  .chatBox{
    flex: 10 1;
  }

  .chatOnline{
    flex: 1px 1;
  }
}

.conversation {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  cursor: pointer;
  margin-top: 2px;
  /* flex-direction: column; */
  background: #ffffff;
}
.conversationLeft {
  display: flex;
  flex-direction: column;
}
.conversation:hover {
  background-color: rgb(245, 243, 243);
}

.conversationImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.conversationName {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  /* .conversationName {
    display: none;
  } */
}

.message {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.messageTop{
    display: flex;
}

.messageImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.81) 0px 2px 4px -1px;
}

.messageText{
    padding: 10px;
    border-radius: 20px;
    background-color: #fff;
    color: black;
    max-width: 300px;
}

.messageBottom{
    font-size: 12px;
    margin-top: 10px;
}

.message.own{
    align-items: flex-end;
}

.message.own .messageText{
    
    background-color: #1877f2;
    color: white;
}
.authentication__header {
  color: white;
  text-align: center;
}

.authentication {
  width: 90%;
  max-width: 25rem;
  margin: 7rem auto;
  text-align: center;
}

.authentication form {
  margin-bottom: 1rem;
}
.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  /* position: fixed; */
  top: 0;
  left: 0;
  background: #838383;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

/* main {
  margin-top: 5rem;
} */

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}
.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.CartButton_button__1iIT8 {
    background-color: transparent;
    border-color: #1ad1b9;
    color: #1ad1b9;
  }
  
  .CartButton_button__1iIT8:hover,
  .CartButton_button__1iIT8:active {
    color: white;
  }
  
  .CartButton_button__1iIT8 span {
    margin: 0 0.5rem;
  }
  
  .CartButton_badge__1CZGy {
    background-color: #1ad1b9;
    border-radius: 30px;
    padding: 0.15rem 1.25rem;
    color: #1d1d1d;
  }
.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
  position: inherit;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: block;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  /* .main-navigation__menu-btn {
    display: none;
  } */

  .main-navigation__header-nav {
    display: block;
  }
}
@media (max-width: 500px) {
  .header_textXS_none {
    display: none;
  }
  /* .main-navigation__menu-btn {
    display: none;
  } */
  .header_textXS {
    font-size: 10px;
  }
  .main-navigation__header-nav {
    display: block;
  }
}
.searchInputs {
    display: flex;
    border: 1px solid;
    border-radius: 12px;
    justify-content: space-around;
    align-items: center;
  }
  
  .search input {
    background-color: white;
    border: 0;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 18px;
    padding: 15px 15px 13px 3px;
    height: 59px;
    width: 158px;
  }
  
  .searchIcon {
    /* height: 60px; */
    /* width: 50px; */
    background-color: white;
    /* display: flex; */
    /* place-items: end; */
    border-radius: 10px;
    /* position: absolute; */
    /* left: 0; */
  }
  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  
  .dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
  }
  
  /* .dataResult::-webkit-scrollbar {
    display: none;
  } */
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  /* a {
    text-decoration: none;
  } */

.singleResult {
    margin: 6px;
    border-bottom: 1px solid #d3d3d3;
    padding: 4px;
    cursor: pointer;
    transition: 0.5s;
}

.singleResult:hover {
    background-color: #1976d2;
    color: #f9f9f9;
}
  
  #clearBtn {
    cursor: pointer;
  }
.single_contact_container:nth-child(even) {
    background: rgb(183, 183, 183);
}

.single_contact_container {
    -webkit-animation: pulsevl 50s infinite;
            animation: pulsevl 50s infinite;
}
@-webkit-keyframes pulsevl {
  0% {
    background-position: center top;
        background-size: 150%;
  }
  10% {
    background-position: center;
        background-size: 120%;
  }
  20% {
    background-position: bottom;
        background-size: 140%;
  }
  30% {
    background-position: center;
        background-size: 160%;
  }
  40% {
    background-position: center top;
        background-size: 120%;
  }
  70% {
    background-position: bottom;
        background-size: 150%;
  }
  80% {
    background-position: center top;
        background-size: 170%;
  }

  100% {
    background-position: center top;
        background-size: 150%;
  }
}
@keyframes pulsevl {
  0% {
    background-position: center top;
        background-size: 150%;
  }
  10% {
    background-position: center;
        background-size: 120%;
  }
  20% {
    background-position: bottom;
        background-size: 140%;
  }
  30% {
    background-position: center;
        background-size: 160%;
  }
  40% {
    background-position: center top;
        background-size: 120%;
  }
  70% {
    background-position: bottom;
        background-size: 150%;
  }
  80% {
    background-position: center top;
        background-size: 170%;
  }

  100% {
    background-position: center top;
        background-size: 150%;
  }
}
.single_contact_container:nth-child(even) {
    background: rgb(183, 183, 183);
}

.single_contact_container {
    -webkit-animation: pulsevl 50s infinite;
            animation: pulsevl 50s infinite;
}
@-webkit-keyframes pulsevl {
  0% {
    background-position: center top;
        background-size: 150%;
  }
  10% {
    background-position: center;
        background-size: 120%;
  }
  20% {
    background-position: bottom;
        background-size: 140%;
  }
  30% {
    background-position: center;
        background-size: 160%;
  }
  40% {
    background-position: center top;
        background-size: 120%;
  }
  70% {
    background-position: bottom;
        background-size: 150%;
  }
  80% {
    background-position: center top;
        background-size: 170%;
  }

  100% {
    background-position: center top;
        background-size: 150%;
  }
}
@keyframes pulsevl {
  0% {
    background-position: center top;
        background-size: 150%;
  }
  10% {
    background-position: center;
        background-size: 120%;
  }
  20% {
    background-position: bottom;
        background-size: 140%;
  }
  30% {
    background-position: center;
        background-size: 160%;
  }
  40% {
    background-position: center top;
        background-size: 120%;
  }
  70% {
    background-position: bottom;
        background-size: 150%;
  }
  80% {
    background-position: center top;
        background-size: 170%;
  }

  100% {
    background-position: center top;
        background-size: 150%;
  }
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0px;
}
* {
  box-sizing: border-box;
}
.pac-container {
  z-index: 999999999999;
}
.css-1ozefeb {
  z-index: 199999999300 !important;
}
.modal {
  z-index: 9999999909 !important;
}
.css-1sucic7 {
  position: fixed;
  z-index: 9999999998 !important;
  inset: 0px;
}
.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  position: fixed;
  z-index: 99999999999999 !important;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
}
.css-1ozefeb {
    margin-left: 0 !important;
}
.css-1laqsz7-MuiInputAdornment-root {
  z-index: 999999999 !important;
}
/* .nav-links a:hover, .nav-links a:active, .nav-links a.active
.chackbox_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
} */
.chackbox_container label {
  min-width: 111px;
  text-align: right;
}
.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}
.chackbox_container input {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
html {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  background: #ffffff;
}

.slide-in-left-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-in-left-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

/* .center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* h1 {
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: #281414;
  z-index: 10;
  margin: 0;
  padding: 0;
} */
/* 
.search {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}

.locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
  cursor: pointer;
}
.App {
  display: flex;
  justify-content: center;
}

label {
  font-weight: bold;
  display: flex;
  margin-bottom: 5px;
}

input + label {
  font-weight: bold;
  display: inline-flex;
  margin-right: 20px;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea,
select {
  display: block;
  width: 400px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* margin-bottom: 20px; */
/* } */

/* .form-control {
  margin-bottom: 20px;
}

.error {
  color: red;
}  */
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

/* .nav-links li {
  margin: 1rem;
} */
.css-11b3ww9-MuiPaper-root-MuiAppBar-root{
    border: 1px solid transparent;
      color: #292929;
      text-decoration: none;
      padding: 0.5rem;
}
.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  background: #efefef;
  border-color: none;
  box-shadow: 1px 2px 5px 1px rgb(0 0 0 / 65%);
  color: #292929;
}

.jobslink {
  font-size: 17px;
  background: rgb(25, 118, 210);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 33px;
  width: 43px;
  height: 42px;
  color: rgb(255, 255, 255);
  padding: 7px 5px 0px 0px;
  margin: 0px 18px 0px 0px;
}

.jobslink:hover {
  background: rgb(0, 35, 70);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 33px;
  color: rgb(255, 255, 255);
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 0px;
  font-size: 13px;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 8px;
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
}
