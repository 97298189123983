img{max-width:100%; max-height: 100%;}
	*{transition: all .5s ease;-moz-transition: all .5s ease;-webkit-transition: all .5s ease}
.my-list {
    background: #fbfbfb;
    width: 100%;
    padding: 10px;
    border: 1px solid #d8d8d86b;
    float: left;
    margin: 15px 0;
    /* border-radius: 5px; */
    box-shadow: 2px 3px 6px #eaeaea99;
    position: relative;
    overflow: hidden;
}
.my_list_img {
    width: 100%;
    height: 250px;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.my_list_img img:hover {
    transform: scale(1.5);
    /* max-width: 100%; */
    /* height: 100%; */
}
.my-list h3:hover {
    color: #2f68b2;
    cursor: pointer;
}
a.btn.btn-info {
    position: absolute;
    top: 35%;
    left: 35%;
}
.my_beac_color {
    background: #ffffffd9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}
.my-list a.btn.btn-info {
    top: 35%;
    left: 35%;
    width: 109px;
    height: 104px;
    padding: 39px 16px;
    color: #fff;
    border: 2px solid #fff;
    background: #000000c7;
    border-radius: 100px;
    /* font-size: 24px; */
}
.my-list a.btn.btn-info:hover {
    background: #000000;
}
.my-list h3{
    text-align: right;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #ccc4c4;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-bottom: 5px;
    min-height: 72px;
    }
.my-list span{
    float:left;
    font-weight: bold;
}
.my-list span:last-child{
    float:right;
}
.my-list .offer{
    width: 100%;
    float: left;
    margin: 5px 0;
    border-top: 1px solid #ccc4c4;
    margin-top: 5px;
    padding-top: 5px;
    color: #00b506;
    }
.detail {
    position: absolute;
    top: -107%;
    left: 0;
    text-align: center;
    background: #fff;
    height: 100%;
    width:100%;
    display: none;
	
}
	
.my-list:hover .detail{top:0;}
.product_top_details_kupon {
    text-align: center;
}
.go_to_advertise {
    float: right;
    width: 260px;
    height: 54px;
    position: relative;
}
.go_to_advertise img {
    width: 50px;
    position: absolute;
    z-index: 99;
}
.go_to_advertise_title {
    top: 12px;
    width: 304px;
    text-align: center;
    background: #ef1e23;
    right: 5px;
    height: 31px;
    position: absolute;
    padding-top: 7px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
}
.go_to_inside_kupon_cont {
    float: right;
    width: 100%;
    margin-top: 14px;
}
.go_to_inside_kupon {
    float: left;
    /* top: 12px; */
    width: 37px;
    /* margin: 0 auto; */
    text-align: center;
    /* background: #ef1e23; */
    color: #fff;
    /* right: 5px; */
    height: 24px;
    /* position: absolute; */
    /* padding-top: 3px; */
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
    /* margin-top: 10px; */
    cursor: pointer;
}
.selected_go_to_inside_kupon {
    /* top: 12px; */
    width: 260px;
    margin: 0 auto;
    text-align: center;
    background: #ef1e23;
    color: #fff;
    /* right: 5px; */
    height: 51px;
    /* position: absolute; */
    padding-top: 10px;
    font-size: 21px;
    font-weight: 400;
    border-radius: 10px;
    margin-top: 25px;
    margin-bottom: 25px;
    cursor: pointer;
}
    .kupon_container {
    max-width:  100%;
}
.amount_details {
    margin-top: 10px;
}
.amount_pre_price {
    color: #8d8d8d;
    text-decoration: line-through;
}
.amount_price {
    color: #2f2f2f;
    font-size: 30px;
    font-weight: 200;
}
.title_kupon {
    text-align: center;
    color: #3b3b3b;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
    min-height: 75px;
}
.kupon_dib {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.the_kupon {
    border: 1px dashed #aeaeae;
    border-radius: 10px;
    padding: 5px;
    height: 412px;
    /* float: right; */
    /* position: relative; */
    overflow: hidden;
    background: #efefef9c;
    margin-bottom: 30px;
}

.number_kupon {
    /* float:  right; */
    width: 58px;
    font-size:  36px;
    text-align: center;
    border: 4px solid #8f8f8f;
    box-shadow: 3px -1px #000000;
    -moz-border-radius: 75px;
    -webkit-border-radius: 75px;
    margin-right: 5px;
    margin-top: 5px;
    z-index: 999;
    position:  absolute;
}

.logo_kupon {
    width: 100%;
    height: 184px;
    background: #fff;
    text-align: center;
}

.logo_kupon img {
    max-width: 100%;
    max-height: 100%;
}
.amount_details {
/*    position:  absolute;
    bottom:  0;
    width:  100%;
    text-align:  right;
    padding-right: 10px;
    padding-top: 3px;
    z-index: 99;
    background: #6c6c6c4d;*/
}

.amount_kupon {
    font-size: 19px;
    font-weight: 300;
    /*margin-top: 10px;*/
    /* color: #040303; */
    /* text-shadow: 2px -2px 3px rgba(240, 255, 0, 0.92); */
    /* float: right; */
}

.amount_desc_kupon {
    /* float: right; */
    padding-left: 9px;
    /* padding-top: 7px; */
    color: #000;
    /* text-shadow: 2px -2px 3px rgba(255, 255, 255, 0.83);*/
} 
.form_box {
    width: 32%;
    float: right;
    margin-left: 10px;
}
.product_hot_container {
    width: 100%;
}
.more_details_p {
    font-size:  20px;
    font-weight:  300;
    margin-top: 20px;
    line-height: 37px;
    max-width: 550px;
}

span.kupon_in_details {
    font-weight:  bold;
    margin-left: 20px;
}
.all_technical_container {
    text-align: right;
    background: #e5e5e5;
    padding: 8px;
}
@media screen and (max-width: 450px){
.my_list_img {
    width: 100%;
    height: 50px;
    text-align: center;
}
#id_reg_prod_box .col-xs-6 {
    padding: unset;
}
.my_list_img {
    height: 75px;
}
.my-list h3 {
    text-align: right;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #ccc4c4;
    margin-bottom: 5px;
    margin-top: 15px;
    padding-bottom: 5px;
    min-height: 72px;
}
.amount_details {
    margin-top: 3px;
    line-height: 17px;
    font-size: 12px;
    width: 100%;
}
.amount_pre_price {
    text-align: center;
}
.amount_price {
    text-align: center;
}
.my-list .offer {
    font-weight: 300;
    font-size: 12px;
}
.go_to_inside_kupon_cont {
    margin-top: 0px;
}
.go_to_inside_kupon {
    /* top: 12px; */
    width: 100%;
    margin: 0 auto;
    text-align: center;
    /*background: #ef1e23;*/
    /*color: #fff;*/
    /* right: 5px; */
    height: 30px;
    /* position: absolute; */
    padding-top: 3px;
    font-size: 15px;
    font-weight: 400;
    /*border-radius: 10px;*/
    margin-top: 0px;
    /*cursor: pointer;*/
}
.col-xs-12 {
    width: 100%;
    padding: unset;
    padding-right: 6px;
}
.amount_desc_kupon {
    min-height: 18px;
}


}
.pirurim {
    color: #3068b2;
    font-weight: 400;
}
.search_widgets_y {
    margin: 40px 0px 40px 0px;
    text-align: center;
}

input.search_input_btn_y {
    border: 1px solid #cacaca;
    font-size: 16px;
    padding: 6px;
    color: #5a5a5a;
    margin-left: 10px;
}

button.search_submit_y {
    background: #afafaf;
    /* height: 18px; */
    width: 85px;
    font-size: 16px;
    border: 1px solid #cacaca;
    font-size: 16px;
    padding: 6px;
    color: #ffffff;
    margin-left: 10px;
}

button.search_submit_y:hover {
    background: #122448;
}
.add_to_card_p {
    width: 100%;
    overflow: hidden;
}

.add_to_card_p #number_of_same_product {
    width: 62px;
    float: right;
    height: 27px;
    border-radius: 0px;
    border: 1px solid #bbb;
    font-size: 16px;
    padding-right: 5px;
    margin-left: 8px;
}

.add_to_card_button_p.add_cart_selected.go_shop {
    color: #ffffff;
    background: #9a0004;
    border-radius: 0px;
    padding: 4px 7px 5px 9px;
    font-weight: 300;
    margin-top: 0px;
    font-size: 13px;
    display: block;
    float: right;
}
</style>
<style>
    .cart_icon img {
        width: 70px;
    }
    .cart_screen{
    position: fixed;
    width: 250px;
    height: 700px;
    left: 80px;
    top: 0px;
    background-color: rgb(154 0 4);
    z-index: 9998;
    }
    .cart_icon {
    position: fixed;
    top: 150px;
    left: 150px;
    z-index: 9999;
    /* border: 1px solid red; */
     display: none;
    }
    
.price_txt_anim {
    position: fixed;
    top: 0px;
    left: 180px;
    z-index: 9999;
    /* border: 1px solid red; */
    font-size: 15px;
    font-weight: bold;
    /* text-shadow: 2px 2px 1px #000000; */
    color: #3165ab;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding-top: 12px;
}
@keyframes fadeAndScale2 {
    0% {
        opacity: 0;
        transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
        /* transform: translate(200px , -200px); */

    }

    30% {
        opacity: 0;
        transform: scale3d(0.3,0.3,3)  skew(-20deg, -20deg);
        /* transform: translate(100px , -100px), rotate(90deg); */
    }

    100% {
        opacity: 1;
        transform: scale3d(1,1,1) skew(0deg, 0deg);
        /* transform: translate(0px , 0px), rotate(160deg); */
    }
}
.fade_in_y1 {
    transform-origin: top center;
    transition: 0.8s;
}