.leaflet-container {
    width: 100%;
    height: calc(90vh - 90px);
  }
/* .Block{
    display: flex;
    align-items: center;
    flex-direction: column;
} */
/* #Resizable{
    border: 1px solid black; 
    height: 200px;
    width: 200px;
} */
#Draggable{
    background: rgba(1, 1, 1, 0.2);
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    cursor: col-resize;
    height: 20px;
    width: 10px;
}