.form_tag_container {
    z-index: 99;
max-width: 300px;
background: #fff;
display: flex;
position: absolute;
flex-direction: column;
}

.tabs_date {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}
.tabs_date_item{
    margin-left: 5px;
    max-width: 218px;
    background: #525252;
    color: #fff;
    padding: 7px;
    cursor: pointer;
    transition: 0.5s;
}
.tabs_date_item:hover {
    background: #1976d2;
}

.tabs_date_item:active {
    background: #002346;
}
.soonWord {
    margin-left: 5px;
    max-width: 218px;
    background: #dddddd;
    color: #383838;
    padding: 19px;
    cursor: pointer;
    text-align: center;
    margin-top: 16px;
}
.react-datepicker__input-container input#date_delivery {
    border-radius: 10px;
    padding: 8px;
}
.search input {
    background-color: white;
    border: 0;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 15px;
    padding: 9px 15px 13px 3px;
    height: 0px;
    width: 158px;
}
.searchInputs {
    display: flex;
    border: 1px solid;
    border-radius: 0px;
    justify-content: space-around;
    align-items: center;
}
.searchIcon {
    /* height: 60px; */
    /* width: 50px; */
    background-color: white;
    /* display: flex; */
    /* place-items: end; */
    border-radius: 10px;
    /* position: absolute; */
    /* left: 0; */
}
.searchIcon svg {
    font-size: 26px;
}
