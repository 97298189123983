.conversation {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  cursor: pointer;
  margin-top: 2px;
  /* flex-direction: column; */
  background: #ffffff;
}
.conversationLeft {
  display: flex;
  flex-direction: column;
}
.conversation:hover {
  background-color: rgb(245, 243, 243);
}

.conversationImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.conversationName {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  /* .conversationName {
    display: none;
  } */
}
