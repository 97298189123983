.form-control {
  margin: 1rem 0;
}

.form-control label,
.form-control input,
.form-control textarea {
  display: block;
}

.form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea {
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  background: #f8f8f8;
  padding: 0.15rem 0.25rem;
}

.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  background: #ebebeb;
  border-color: #510077;
}

.form-control--invalid label,
.form-control--invalid p {
  color: red;
}

.form-control--invalid input,
.form-control--invalid textarea {
  border-color: red;
  background: #ffd1d1;
}
.radio-form-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.radio-form-container label {
  font-weight: 600;
}
.radio-form-container .form-control {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: 6px;
}
.radio-form-container .form-control label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  min-width: 30px;
}
.radio-form-container .chackbox_container {
  display: flex;
  justify-content: flex-start;
  width: unset;
  min-width: 83px;
}
.radio-form-container .chackbox_container label {
  font-weight: 400;
  margin-bottom: 0.5rem;
  min-width: 22px;
}