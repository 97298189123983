.slideshowContainer {
  position: relative;
  overflow: hidden;
  /*margin: 0px 0 0px;*/
  margin: 0 auto;
  width: 100%;
  height: 561px;
}

.imageSlides {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  /*min-height: 100%;*/
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: -1;
}

/* add 'visible' class via Javascript */
.visible {
  opacity: 1;
}

.slideshowArrow {
  font-size: 7em;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.slideshowArrow:hover {
  opacity: 0.75;
}

#leftArrow {
  position: absolute;
  left: 4%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#rightArrow {
  position: absolute;
  right: 4%;
  top: 50%;
  transform: translate(50%, -50%);
}

.slideshowCircles {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.circle {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid 2px rgba(255, 255, 255, 0.5);
  transition: 1s ease-in-out;
}

.dot {
  background-color: rgba(255, 255, 255, 0.7);
  border: solid 2px rgba(255, 255, 255, 0.5);
}