.place-form {
  /* position: relative; */
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 6px;
  background: white;
    position: fixed;
    z-index: 99;
    max-width: 289px;
}
.place-form2 {
  /* position: relative; */
  list-style: none;
  margin: 0 auto;
  padding: 1rem;
  /* width: 90%; */
  max-width: 40rem;
  box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
  border-radius: 6px;
  background: white;
  position: absolute;
  max-height: 100vh;
  z-index: 99;
  max-width: 289px;
  /* display: flex; */
  overflow: auto;
  flex-direction: column;
}
