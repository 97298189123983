.searchInputs {
    display: flex;
    border: 1px solid;
    border-radius: 12px;
    justify-content: space-around;
    align-items: center;
  }
  
  .search input {
    background-color: white;
    border: 0;
    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 18px;
    padding: 15px 15px 13px 3px;
    height: 59px;
    width: 158px;
  }
  
  .searchIcon {
    /* height: 60px; */
    /* width: 50px; */
    background-color: white;
    /* display: flex; */
    /* place-items: end; */
    border-radius: 10px;
    /* position: absolute; */
    /* left: 0; */
  }
  
  input:focus {
    outline: none;
  }
  .searchIcon svg {
    font-size: 35px;
  }
  
  .dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
  }
  
  /* .dataResult::-webkit-scrollbar {
    display: none;
  } */
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  /* a {
    text-decoration: none;
  } */

.singleResult {
    margin: 6px;
    border-bottom: 1px solid #d3d3d3;
    padding: 4px;
    cursor: pointer;
    transition: 0.5s;
}

.singleResult:hover {
    background-color: #1976d2;
    color: #f9f9f9;
}
  
  #clearBtn {
    cursor: pointer;
  }